import reducePreloads from '@swe/shared/network/helpers/reduce-preloads';
import { useIntl } from '@swe/shared/tools/intl';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { getSeo } from 'common/use-cases/use-seo';
import useCatalogQuery from 'domains/catalog/use-cases/use-catalog-query';
import DiscountList from 'domains/product/containers/deals-list';
import GetPromoBannerListEndpoint from 'endpoints/promotion/get-promotion-list';
import GetShopInfoEndpoint from 'endpoints/shop/get-shop-info';
import { ShopInfo } from 'entities/shop/info';

const DiscountsPage: ApplicationPage = () => {
  const { intl } = useIntl();
  const { buildCatalogLink } = useCatalogQuery();

  return (
    <Page
      header={{
        title: intl.t('pages.discounts.title', undefined, 'Discounts'),
        breadcrumbs: [
          {
            text: 'Menu',
            href: buildCatalogLink({ filters: null, searchTerm: null, subPath: null, page: null }),
          },
          {
            text: intl.t('pages.discounts.breadcrumbTitle', undefined, 'Discounts'),
          },
        ],
        hideBackButton: true,
      }}
    >
      <DiscountList maxNotLazyItems={6} />
    </Page>
  );
};

DiscountsPage.preload = ({ headers, queryClient }, { saleType, platformOs }) => {
  return reducePreloads([GetPromoBannerListEndpoint.preload({ saleType, platformOs }, { headers }, queryClient)]);
};

DiscountsPage.getMeta = ({ intl, queryClient, dealerName }) => {
  const storeInfo = queryClient.getQueryData(GetShopInfoEndpoint.key())! as ShopInfo;
  const { builder, payload } = getSeo(dealerName, storeInfo);
  const { name, cityName, stateName, saleTypesFormatted, fulfillmentTypesFormatted } = payload;

  builder.title.add([
    intl.t(
      'pages.discounts.documentTitle',
      { name, cityName, stateName },
      'Discounts and Best Deals for cannabis products online | {name} | {cityName}',
    ),
  ]);
  builder.description.add([
    'Want to find the best deals and discounts for cannabis products online?',
    `${name} - your best source for premium products in ${cityName}`,
    saleTypesFormatted,
    ...fulfillmentTypesFormatted,
  ]);

  builder.keywords.add([cityName, name, 'Discounts cannabis', 'best deals cannabis']);

  return builder.toProps();
};

export default DiscountsPage;
